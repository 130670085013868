





























































































import { Vuetable, VuetablePagination } from "vuetable-2";
import UsersService from "@/services/UsersService";
import * as _ from "lodash";
import Component from "vue-class-component";
import Vue from "vue";
import { PaginatedResult } from "@/shared/types/paginated-result.class";
import { User } from "@/models/user.model";
import { namespace } from "vuex-class";
import UserTableDefinition from "./UserTableDefinition.vue";
import { ColumnConfig } from "@/models/column-config.model";
import { Formatter } from "@/utils/formatter.util";

const usersNamespace = namespace("Users");
const authNamespace = namespace("Auth");
const userTableConfigNamespace = namespace("UserTableConfig");

@Component({
  components: { Vuetable, VuetablePagination, UserTableDefinition },
})
export default class UsersView extends Vue {
  $refs!: Vue["$refs"] & {
    userstable: Vuetable;
    pagination: VuetablePagination;
  };
  showTableConfig = false;
  showImport = false;
  isLoading = false;
  importFile = null;
  perPage = 5000;
  searchString = "";

  @authNamespace.Getter("isAdmin")
  isAdmin!: boolean;

  @usersNamespace.Getter("all")
  users!: PaginatedResult<User>;

  @userTableConfigNamespace.Getter("all")
  userTableConfig!: ColumnConfig[];

  @userTableConfigNamespace.Action("fetchAll")
  fetchUserTableConfig!: () => Promise<ColumnConfig[]>;

  @userTableConfigNamespace.Action("update")
  updateUserTableConfig!: (config: ColumnConfig[]) => Promise<ColumnConfig[]>;

  get fields() {
    const fields: any[] = [];
    for (const columnConfig of this.userTableConfig) {
      const fieldDef: any = {
        name: columnConfig.field,
        sortField: columnConfig.field,
        // title: columnConfig.label,
      };
      if (columnConfig.formatter) {
        fieldDef.formatter = Formatter[columnConfig.formatter];
      }
      fields.push(fieldDef);
    }
    fields.push({ name: "actions" });
    return fields;
  }

  get userCount() {
    return this.users?.total;
  }

  get usersInfo() {
    const { data, ...rest } = this.users;
    return rest;
  }

  get locale() {
    return this.$root.$i18n.locale;
  }
  dataManager(sortOrder, pagination) {
    if (this.users.data.length < 1) return;

    let local = this.users.data;

    // sortOrder can be empty, so we have to check for that as well
    if (sortOrder.length > 0) {
      local = _.orderBy(local, sortOrder[0].sortField, sortOrder[0].direction);
    }

    pagination = this.$refs.userstable.makePagination(
      local.length,
      this.perPage
    );
    let from = pagination.from - 1;
    let to = from + this.perPage;

    return {
      pagination: pagination,
      data: local,
    };
  }

  doSearch() {
    this.doFetchAll();
  }

  onPaginationData(paginationData) {
    this.$refs.pagination.setPaginationData(paginationData);
  }

  onChangePage(page) {
    console.log("onC", page);
    this.$refs.userstable.changePage(page);
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchUserTableConfig();
    await this.$store.dispatch("Users/fetchAll", {
      search: this.searchString,
    });
    this.isLoading = false;
  }

  async doSaveTableConfig() {
    this.isLoading = true;
    await this.updateUserTableConfig(this.userTableConfig);
    await this.fetchUserTableConfig();
    this.isLoading = false;
  }

  editUser(user) {
    this.$router.push({ name: "user-detail", query: { id: user.id } });
  }

  addUser() {
    this.$router.push({ path: "user-detail" });
  }

  async doImport() {
    this.isLoading = true;
    try {
      const response = await UsersService.import(this.importFile);
    } catch (e) {
      this.$root.$bvToast.toast(e.response.data.message, {
        variant: "danger",
      });
    }
    this.doFetchAll();
  }

  async doExport() {
    const response = await UsersService.export(this.locale);
    const blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "users.xlsx";
    link.click();
    URL.revokeObjectURL(link.href);
  }

  async deleteUser(user) {
    this.isLoading = true;
    const isConfirmed = await this.$bvModal.msgBoxConfirm(
      `Are you sure you want to remove ${user.email}?`,
      {
        title: "Confirm delete",
        okVariant: "danger",
        centered: true,
      }
    );
    if (isConfirmed) {
      await this.$store.dispatch("Users/delete", user.id);
      return this.doFetchAll();
    }
    this.isLoading = false;
  }

  async created() {
    this.doFetchAll();
  }
}
