import { DateTime } from "luxon";

export class Formatter {
  static date(value: string) {
    console.log(value);
    return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
  }

  static datetime(value: string) {
    return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT);
  }

  static indicator(value: string) {
    if (value) {
      return '<span class="text-success">●</span>';
    }
    return '<span class="text-danger">●</span>';
  }
}
