
















































import { AvailableField } from "@/models/available-field.model";
import { ColumnConfig } from "@/models/column-config.model";
import Vue from "vue";
import Component from "vue-class-component";
import { VModel } from "vue-property-decorator";
import draggable from "vuedraggable";
import { namespace } from "vuex-class";

const userFiltersNamespace = namespace("UserFilters");

@Component({ components: { draggable } })
export default class UserTableDefinition extends Vue {
  @VModel()
  tableConfig!: ColumnConfig[];

  @userFiltersNamespace.Getter("availableFields")
  availableFields!: AvailableField[];

  @userFiltersNamespace.Action("fetchAvailableFields")
  fetchAvailableFields!: () => Promise<AvailableField[]>;

  get formatters() {
    return [null, "date", "datetime", "indicator"];
  }

  doDelete(index: number) {
    this.tableConfig.splice(index, 1);
  }
  addColumn() {
    this.tableConfig.push(new ColumnConfig("email", ""));
  }

  created() {
    this.fetchAvailableFields();
  }
}
